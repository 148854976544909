.tableWrapper {
  width: 100%;
  height: 100%;
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
  //overflow-y: scroll !important;
}

.table {
}

.tableHeader {
}

.tableHeaderRow {
}

.tableHeaderRowCell {
  font-size: 2rem !important;
}

.tableBody {
}

.tableRowHeader {
}

.tableRowHeaderExpandCell {
}

.tableRowHeaderCell {
  font-size: 1.5rem !important;
}

//these are the nested tables for the lab results
.labResultRowTitle {
  font-size: 2rem !important;
  padding: 1rem !important;
}

.labResultRowTable {
  height: 75%;
  overflow-y: scroll !important;
  background-color: lightgray;
}

.labResultRowTableHeader {
  padding: 1.25 rem;
}

.labResultRowTableHeaderRow {
  padding: 1 rem;
}

.labResultRowTableHeaderCell {
  font-size: 2rem !important;
  padding: 1rem !important;
}

.labResultRowTableBody {
}

.labResultRowTableRow {
}

.labResultRowTableRowCell {
  font-size: 1.5rem !important;
}
