#header {
  display: flex;
  flex-direction: row;
}

.headerLogo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  & img {
    padding: 0.25rem;
  }

  @media screen and (max-width: 715px) {
    width: 100%;

    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    div {
      margin: 0;
      padding: 1rem;
    }
  }
}

.headerGreeting {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 10rem 0 5rem;
}

.redirectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: btn;
  padding: 5rem 1rem 0.5rem 1rem;

  & a {
    text-decoration: none;
  }

  & button {
    font-size: 2rem;
    font-weight: bolder;
    letter-spacing: 0.1rem;
    border-radius: 1rem;
    border: none;
    font-family: inherit;
    background-color: #c4a445;
    width: 20rem;
    color: black;
    cursor: pointer;
    padding: 1rem;
    align-self: center;
    text-decoration: none;
    box-shadow: 0.75rem 0.5rem 2rem rgba(0, 0, 0, 0.4);
  }
}

.signOutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: btn;
  padding: 5rem 1rem 0.5rem 1rem;

  & a {
    text-decoration: none;
  }

  & button {
    font-size: 2rem;
    font-weight: bolder;
    letter-spacing: 0.1rem;
    border-radius: 1rem;
    border: none;
    font-family: inherit;
    background-color: #6caac4;
    width: 20rem;
    color: black;
    cursor: pointer;
    padding: 1rem;
    align-self: center;
    text-decoration: none;
    box-shadow: 0.75rem 0.5rem 2rem rgba(0, 0, 0, 0.4);
  }
}
