#demographicsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  height: fit-content;
  margin: 2rem;
}

.kvpWrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: #7f6e40;
  padding: 0.25rem;
  margin: 0.25rem;
  min-width: 35rem;
  min-height: 7.5rem;
  border-radius: 1rem;

  @media screen and (max-width: 715px) {
    min-width: 25rem;
    min-height: 7.5rem;
  }
}

.key {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  background-color: #6caac4;
  font-weight: bold;
  margin: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;
  width: 40%;
}

.value {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  background-color: white;
  margin: 0.5rem;
  padding: 1rem;
  font-weight: 600;
  border-radius: 1rem;
  width: 60%;
}
