.filler {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45%;
  width: 85%;
  background-color: #806e40;
  border-radius: 100rem;
  text-align: center;

  & p {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0 1rem 0 1rem;
  }
}
