#patientDataHeaderWrapper {
  display: grid;
  grid-template-areas: "patientDataHeaderData";
  // grid-template-areas: "patientDataHeaderPic patientDataHeaderData";
  //grid-template-columns: 0.15fr 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1rem 1rem;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

// #patientDataHeaderPicWrapper {
//   grid-area: patientDataHeaderPic;
//   background: #6caac4;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 1rem;
//   @media screen and (max-width: 784px) {
//     display: none;
//   }
// }

// #patientDataHeaderPic {
//   background-color: white;
//   width: 90%;
//   height: 90%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   & img {
//     height: 100%;
//     width: 85%;
//   }
//   border-radius: 1rem;

//   @media screen and (max-width: 784px) {
//     display: none;
//     & img {
//       display: none;
//     }
//   }
// }

#patientDataHeaderDataWrapper {
  grid-area: patientDataHeaderData;
  background: #6caac4;
  display: flex;
  //justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 1rem;
  flex-direction: row;
  @media screen and (max-width: 715px) {
    font-size: 1.5rem;
  }
}

#patientDataHeaderData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 715px) {
    font-size: 1.5rem;
  }
}

#patientDataHeaderDataList {
  list-style: none;
  //margin-left: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  & li {
    font-weight: bold;
    font-size: 2rem;
    margin: 0.5rem;
    background-color: white;
    padding: 0.25rem;
    border-radius: 1rem;

    & span {
      padding: 0 0.75rem 0 0.75rem;
    }
  }

  @media screen and (max-width: 715px) {
    & li {
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0.5rem;
      background-color: white;
      padding: 0.25rem;
      border-radius: 1rem;

      & span {
        padding: 0 0.75rem 0 0.75rem;
      }
    }
  }
}
