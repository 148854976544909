#patientDataCategoriesWrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-evenly;
  align-items: space-evenly;

  & p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    background-color: white;
    padding: 0.5rem;
    border-radius: 1rem;
  }
}

#patientDataCategoriesList {
  list-style: none;
  height: 100%;
  width: 100%;

  & li {
    margin: 1rem;
    //background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      padding: 0.75rem;
      font-weight: 600;
      border-radius: 1rem;

      &:visited {
        color: black;
      }
      text-decoration: none;
      width: 100%;
      height: 100%;
      &:hover {
        cursor: pointer;
        color: blue;
      }
    }
  }
}
