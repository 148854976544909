.heading {
  grid-area: h2;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding-top: 1rem;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & h2 {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    color: white;
  }
}

.formControl {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  grid-area: search;
  background-color: #6caac4;
  border-radius: 4rem;
  margin: 0 0.75rem 0 0.75rem;
  //padding: 0.75rem;
}

.formGroup {
  padding: 1rem 0.25rem 1rem 0.25rem;
  margin: 0.25rem;

  & label {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    margin-left: 0.25rem;
    margin-top: 0.25rem;
    display: block;
  }

  & input {
    font-size: 1.25rem;

    padding: 0.5rem;
    border-radius: 0.3rem;
    border: none;
    font-family: inherit;
    border-bottom: 0.3rem solid transparent;
    width: 90%;
    display: block;
    color: inherit;
    transition: all 0.3s;
    margin-top: 0.5rem;
  }
}

.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: btn;
  padding: 1rem 0.5rem 1rem 0.5rem;

  & button {
    font-size: 2rem;
    font-weight: bolder;
    letter-spacing: 0.1rem;
    border-radius: 1rem;
    border: none;
    font-family: inherit;
    background-color: #c4a445;
    width: 20rem;
    color: black;
    cursor: pointer;
    padding: 1rem;
    align-self: center;
    box-shadow: 0.75rem 0.5rem 2rem rgba(0, 0, 0, 0.4);
  }
}

.datePicker {
  font-size: 1.5rem !important;
}

.select {
  display: block;
  width: 100%;
  font-size: 1.25rem;

  padding: 0.5rem;
  border-radius: 0.3rem;
  border: none;
  font-family: inherit;
  border-bottom: 0.3rem solid transparent;
  width: 90%;
  display: block;
  color: inherit;
  transition: all 0.3s;
  margin-top: 0.5rem;

  & option {
  }
}
