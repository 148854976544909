#patientDataWrapper {
  grid-area: content;
  background-color: #3c6678;
  display: grid;
  grid-template-areas:
    "patientDataHeader patientDataHeader"
    "dataCategories categoryData"
    "dataCategories categoryData";
  grid-template-columns: 0.25fr 1fr;
  grid-template-rows: 0.1fr 1fr;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 4rem;
  overflow-y: auto;
  @media screen and (max-width: 600px) {
    font-size: 1.25rem;
  }
}

#patientDataHeader {
  grid-area: patientDataHeader;
}

#patientDataCategories {
  grid-area: dataCategories;
  background: #6caac4;
  display: grid;
  border-radius: 1rem;
}

#categoryData {
  grid-area: categoryData;
  background: #c4ae6c;
  display: flex;
  border-radius: 1rem;
}
