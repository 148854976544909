*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

/* theme colors */
/*split complementary #6CAAC4 #3C6678 #776736 #7345C4 #4A3078  */
/* complementary  #C4A445 #C4AE6C #776736 #314BC4 #364278 */

.App,
.grid-container {
  display: grid;
  min-height: 100vh;
  min-width: 90vw;
  grid-template-areas:
    " header header header "
    " content content content "
    " content content content "
    " footer footer footer ";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.1fr 0.25fr 1fr 0.1fr;
  /* margin: 0.5rem; */
  margin: 0 0.75rem 0 0.75rem;
  gap: 0.25rem;
  font-size: 1.5rem;
}

#header {
  background-color: white;
  grid-area: header;
  display: grid;
  grid-template-columns: 0.25fr 0.5fr 0.5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "logo greeting";
  padding: 0.25rem;
}

#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  grid-area: footer;
}

@media screen and (max-width: 600px) {
  .App,
  .grid-container {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 715px) {
  .App,
  .grid-container {
    grid-template-areas:
      " header header header "
      " content content content "
      " content content content "
      " footer footer footer ";
    grid-template-columns: 0.25fr 1fr 0.25fr;
    grid-template-rows: 0.1fr 0.25fr 1fr 0.1fr;
  }

  #header {
    grid-template-columns: 0.5fr;
    grid-template-rows: 1fr;
    grid-template-areas: "greeting";
    justify-content: center;
  }
}
/* #search-bar {
  background-color: lightgray;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.25fr 0.5fr 0.25fr;
  grid-template-areas:
    "h2"
    "search"
    "btn";
  border-radius: 8rem;
} */

/* #search-result-grid {
  background-color: #806e40;
  border-radius: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 1.5rem;
} */
