#searchWrapper {
  grid-area: content;
  background-color: #f0f0f0;
  display: grid;
  grid-template-areas:
    "searchBar"
    "searchIndex";
  grid-template-columns: 1fr;
  grid-template-rows: 0.25fr 1fr;
}

#searchBar {
  grid-area: searchBar;
  background-color: #3c6678;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "h2"
    "search"
    "btn";
  border-radius: 2rem;
  height: 100%;
  font-size: 1.5rem;
}

#searchIndex {
  grid-area: searchIndex;
  background-color: #3c6678;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 1.5rem;
  overflow-y: scroll;
}
